// These are the default Material UI breakpoints.
// They cannot be exported from the theme into CSS variables,
// because CSS variables cannot be used inside media queries.
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

// Up apply to resolutions above the breakpoint, while down apply to resolutions below the breakpoint.
// For example, if we want certain styles to only apply to phones, we would use sm-down (below 600px).
// It is best to follow mobile the first approach whenever possible, so the -up versions are preferred.
$breakpoints: (
  'sm-up': (
    min-width: $breakpoint-sm,
  ),
  'sm-down': (
    max-width: $breakpoint-sm,
  ),
  'md-up': (
    min-width: $breakpoint-md,
  ),
  'md-down': (
    max-width: $breakpoint-md,
  ),
  'lg-up': (
    min-width: $breakpoint-lg,
  ),
  'lg-down': (
    max-width: $breakpoint-lg,
  ),
  'xl-up': (
    min-width: $breakpoint-xl,
  ),
  'xl-down': (
    max-width: $breakpoint-xl,
  ),
) !default;

// Usage example: `@include responsive('md-up') { ...styles }`
@mixin responsive($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Usage example: `color: color(primary);`
// The names are the same as in material, they are exported in AppStyles.tsx.
@function color($color-name) {
  @return var(--color-#{$color-name});
}

// https://randomtree.atlassian.net/browse/PLAT-438
body {
  overflow: hidden;
}

// This element measures the visual length of labels.
#ruler {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
}

code,
.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

.full-width {
  width: 100%;
}

.juvo-recommendation {
  background-color: color(info);
}

.juvo-error {
  color: color(error);
}

.typography {
  color: color(black);

  &-body-3 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  &-subtitle-3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }

  &-overline {
    font-size: 12px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &-caption {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
  }
}

.when-combo > .MuiTabs-root {
  & + * {
    padding: 0 16px;
  }
}

.juvo-input-control,
.juvo-input-control-oa {
  margin-bottom: 18px !important;
}

.juvo-input-label {
  position: relative !important;
  white-space: normal !important;
  transform: none !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  line-height: 1.4rem !important;

  &:focus {
    color: color(primary);
  }

  &.Mui-disabled {
    color: color(black) !important;
  }
}

.tab-container .floating-action-bar {
  top: 16px;

  @include responsive('md-down') {
    top: 32px;
  }
}

.juvo-input-base {
  &:after {
    visibility: hidden;
    display: block;
    content: ' ';
    background-image: url('assets/input-error.svg');
    background-size: 17px 16px;
    height: 16px;
    width: 17px;
    padding: 8px;
    margin-left: 8px;
  }

  &.Mui-error:after {
    visibility: visible;
  }

  &.Mui-disabled:after {
    visibility: visible;
    background-image: url('assets/input-disabled.svg');
  }

  &.Mui-error .MuiInputBase-input {
    outline: 2px solid color(error) !important;
  }

  &.Mui-disabled .MuiInputBase-input {
    background-color: #aaaecf24;
    outline: 1px solid #6f749f;
  }

  &.recommendation .MuiInputBase-input {
    background-color: color(info);
  }
}

.juvo-compare-input-base,
.juvo-no-validation {
  display: flex;
  align-items: center;

  &:after {
    visibility: hidden;
    display: block;
    content: '';
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    height: 20px;
    width: 20px;
    padding: 8px;
    margin-left: 8px;
  }

  &.invalid {
    outline-color: color(error) !important;
    outline-width: 2px;
    &:after {
      visibility: visible;
      background-image: url('assets/input-error.svg');
    }
  }

  &.valid {
    outline-color: color(success) !important;
    outline-width: 2px;
    &:after {
      visibility: visible;
      background-image: url('assets/input-valid.svg');
    }
  }

  &.mismatch {
    outline-color: color(warning-light) !important;
    outline-width: 2px;
    &:after {
      visibility: visible;
      background-image: url('assets/input-mismatch.svg');
    }
  }
}

.juvo-compare-input {
  border: none;
  outline: none;
  padding: 0;
}

.input-ruler {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.juvo-compare-input-base .juvo-compare-input,
.juvo-no-validation input,
.juvo-compare-input + .juvo-compare-input-ghost {
  font-size: 16px;
  line-height: 20px;
  outline: none;
  margin-right: 6px;
  padding: 0;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

.juvo-compare-input-ghost {
  position: absolute;
  display: inline-block;
  white-space: pre-wrap;
  height: 0;
  opacity: 0;
  z-index: -100;
  pointer-events: none;
}

.juvo-input-base .MuiInputBase-input,
.juvo-input-base-word .MuiInputBase-input,
.juvo-compare-input-base,
.juvo-no-validation {
  position: relative;
  padding: 10px 12px;
  // outline instead of border to avoid height growing on hover and focus
  outline: 1px solid color(border);
  font-size: 16px;
  line-height: 20px;
  margin: 0 1px;

  &:hover {
    outline: 2px solid color(hover);
  }

  &:focus {
    outline: 2px solid color(primary);
  }
}

.juvo-no-validation textarea {
  padding: 10px 12px;
}

.juvo-input-base-word .MuiInputBase-input::before {
  display: none !important;
}

.juvo-compare-input-base {
  width: 100%;
}

.juvo-chat-input {
  padding-right: 40px !important;
  &::after {
    display: none;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: color(secondary);
}

*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: color(secondary);
}
*::-webkit-scrollbar-thumb {
  background-color: #888;
  border: 2px solid color(secondary);
}
.MuiPickersPopper-root {
  z-index: 130000 !important;
}

.MuiTooltip-popper {
  z-index: 1000000 !important;
}
.MuiTabs-indicator {
  background-color: color(primary-dark) !important;
}

@keyframes chatInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes chatInFromRight {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes drawerFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.builder-drawer {
  transform: translateX(100%);
  animation: drawerFromRight 300ms ease-in-out;
  animation-fill-mode: forwards;
}
